body {
  background-color: $body-color;
  overflow-x: hidden;
}

::selection {
  background: lighten($color: $primary-color, $amount: 10);
  color: $white;
}

/* preloader */

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 142px;
    height: 40px;
    margin: -20px 0 0 -71px;
    background: white;

    .dot {
      position: absolute;
      width: 16px;
      height: 16px;
      top: 12px;
      left: 15px;
      background: $primary-color;
      border-radius: 50%;
      transform: translateX(0);
      animation: dot 2.8s infinite;
    }

    .dots {
      transform: translateX(0);
      margin-top: 12px;
      margin-left: 31px;
      animation: dots 2.8s infinite;

      span {
        display: block;
        float: left;
        width: 16px;
        height: 16px;
        margin-left: 16px;
        background: $primary-color;
        border-radius: 50%;
      }
    }

    @keyframes dot {
      50% {
        transform: translateX(96px)
      }
    }

    @keyframes dots {
      50% {
        transform: translateX(-31px)
      }
    }
  }
}

ol,
ul {
  list-style-type: none;
  margin: 0px;
}

img {
  vertical-align: middle;
  border: 0;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a,
button,
select {
  cursor: pointer;
  transition: .2s ease;

  &:focus {
    outline: 0;
  }
}

a:hover,a.text-dark:hover {
  color: $primary-color !important;
}

.slick-slide {
  outline: 0;
}

.section {
  padding-top: 90px;
  padding-bottom: 90px;

  &-sm {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  &-title {
    margin-bottom: 30px;
  }
}

.bg-cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-fixed{
  background-attachment: fixed;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
}

.border-primary {
  border-color: $border-color !important;
}

/* overlay */

.overlay {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: $black;
    opacity: .5;
  }
}

.outline-0 {
  outline: 0 !important;
}

.d-unset {
  display: unset !important;
}

.bg-primary {
  background: $primary-color !important;
}

.bg-secondary{
  background: $secondary-color !important;
}

.text-primary {
  color: $primary-color !important;
}

.text-color {
  color: $text-color;
}

.text-dark {
  color: $text-color-dark !important;
}

.font-secondary {
  font-family: $secondary-font !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.zindex-1 {
  z-index: 1;
}

.overflow-hidden {
  overflow: hidden;
}

.form-control {
  border: 0;
  border-bottom: 1px solid $border-color;
  border-radius: 0;

  &:focus {
    outline: 0;
    box-shadow: none;
    border-color: $primary-color;
  }
}

textarea.form-control{
  height: 150px;
}