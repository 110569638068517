// Color Variables
$primary-color: #ff6f00;
$secondary-color: #fdefe6;
$text-color: #585757;
$text-color-dark: #000000;
$text-color-light: #767575;
$body-color: #fff;
$border-color: #ababab;
$black: #000;
$white: #fff;
$gray: #eaeaea;

// Font Variables
$primary-font: 'Open Sans', sans-serif;
$secondary-font: 'hanskendrick';
$icon-font: 'themify';