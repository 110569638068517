/*  typography */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Playfair+Display:400,700');

@font-face {
  font-family: 'hanskendrick';
  src: url("../plugins/fonts/hanskendrick-regular-webfont.woff") format("woff");
  font-style: normal;
  font-display: swap;
}

body {
  line-height: 1.5;
  font-family: $primary-font;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  color: $text-color;
}

p, .paragraph {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.5;
  font-family: $primary-font;
}

h1,h2,h3,h4,h5,h6 {
  color: $text-color-dark;
  font-family: $secondary-font;
  font-weight: 600;
  line-height: 1.2;
}

h1, .h1{
  font-size: 80px;
  @include tablet {
    font-size: 55px;
  }
}

h2, .h2{
  font-size: 50px;
  @include tablet {
    font-size:45px;
  }
}

h3, .h3{
  font-size: 40px;
}

h4, .h4{
  font-size: 30px;
}

h5, .h5{
  font-size: 20px;
}

h6, .h6{
  font-size: 16px;
}
